import * as React from "react"
import { graphql, Link } from 'gatsby';
import Layout from "../../components/layout";
import {HeroBox, HeroImageBox, InfoBox, BottomHeroDiv} from "../mylib/page-constants";
import {makeRichText} from "../../components/richtext";
import RenderContentfulImage, {IMAGE_TYPE} from "../../components/renderContentfulImage";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import { styled } from '@mui/material/styles';
import {Card, CardContent, Typography} from "@mui/material";

var showScore;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const ItemLeft = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const ItemReview = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function makeTargets(t) {
    return (
        <Card sx={{ width: 200 }}>
            <CardContent>
            <div>Target</div>
            <div><Link to={t.gatsbyPath}>{t.name}</Link></div>
            <div>
                <Link to={t.gatsbyPath}>
                    <RenderContentfulImage node={t.logoSquareWhite} classType={ IMAGE_TYPE.MAINLOGO}/>
                </Link>
            </div>
            </CardContent>
        </Card>
    )
}

const WIDTH_ONE_GRID = 200;
function makeReviews(review) {
    if ((review.targets == null) || (review.targets.length == 0)) {
        return null;}
    let widthReview = (WIDTH_ONE_GRID * review.targets.length) + (45 * review.targets.length)
    return(
        <Grid item  >
            <ItemReview>
                <Card sx={{ width: widthReview }}>
                    <CardContent>
                <div>{ showScore ? review.score : 0 }</div>
                <h3>{ review.title }</h3>
                <p>{review.targets.length}</p>
                { review.targets.map((target) => makeTargets(target)) }
                    </CardContent>
                </Card>
            </ItemReview>
        </Grid>
    )
}


function iterateReviews(reviews) {
    if ((reviews == null) || reviews.length == 0) {
        return null
    }
    { return reviews.map((item) => makeReviews(item)) }
}

export default function contentfulList(props) {
    showScore = props.data.contentfulList.showScore;

    return (
        <Layout>
            <Grid container={true} spacing={2} justifyContent={'space-between'} >
                <Grid item xs={12} >
                        <ItemLeft>
                        <RenderContentfulImage node={props.data.contentfulList.hero} classType={ IMAGE_TYPE.HERO}/>
                            <Typography noWrap={false}>
                        <h1 className='headerText' >{ props.data.contentfulList.name}</h1>
                        { props.data.contentfulList.shortDescription }
                    <h2>About { props.data.contentfulList.name }</h2>
                            </Typography>
                        </ItemLeft>
                </Grid>
                <Grid item xs={12} >
                    <ItemLeft>
                        Description
                        { makeRichText(props.data.contentfulList.description) }
                    </ItemLeft>
                </Grid>
                <Grid item xs={12} container={true} spacing={2} justifyContent={'space-between'}>
                    { iterateReviews(props.data.allContentfulReview.nodes)}
                </Grid>
            </Grid>

        </Layout>
    )
}


export const query = graphql`
 query List($slug: String) {
    contentfulList(slug: {eq: $slug}) {
      id
      slug
      gatsbyPath(filePath: "/lists/{contentfulList.slug}")
      name
      shortDescription
      showScore
      description {
        raw
      }
 
      hero {
           gatsbyImageData(layout: FULL_WIDTH)
           title
           }
      
    }
    allContentfulReview(filter: {list: {slug: {eq: "innovative-retailers"}}} 
    sort: {fields: score})
    {
    nodes {
    title
    score
    targets {
    ... on ContentfulCompany {
      name
      gatsbyPath(filePath: "/organizations/{contentfulCompany.slug}")
      logoSquareWhite {
            gatsbyImageData(width:300)
            title
            file {
              contentType
            }
            svg {
              content
              originalContent
              dataURI
              absolutePath
              relativePath
            } 
            }
    }
    ... on ContentfulProject {
      name
      gatsbyPath(filePath: "/projects/{contentfulProject.slug}")
      logoSquareWhite {
            gatsbyImageData(width:300)
            title
            file {
              contentType
            }
            svg {
              content
              originalContent
              dataURI
              absolutePath
              relativePath
            } 
            }
    }


    }
  }}
}
`

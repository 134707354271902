import React from 'react'
import styled from "@emotion/styled";
import SideBar from "./sidebar"
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image";
import {css} from "@emotion/react";
import {DefaultHero} from "./static-images";
import {MainLogoImage, MainLogoSVG} from "../pages/mylib/page-constants";
import {HeroImage} from "../pages/mylib/page-constants";


export const IMAGE_TYPE = {
    HERO: 'heroImage',
    MAINLOGO: 'mainLogoImage'
}

// Render inline SVG with fallback non-svg images

function renderImage(node, classType) {
    if (node == null) {
        return DefaultHero();
    }
    let file = node.file;
    let svg = node.svg;
    let gatsbyImageData = node.gatsbyImageData;
    let alt = node.name;

    if ((file != null) && file.contentType === 'image/svg+xml') {
        if (svg && svg.content) {
            // Inlined SVGs
            return (
                        <div  dangerouslySetInnerHTML={{ __html: svg.content }}  />
            )
        }

        // SVGs that can/should not be inlined
        if (classType == IMAGE_TYPE.HERO) {
            return  <GatsbyImage image={getImage(gatsbyImageData)} alt={ alt } /> }
        else {
            return <GatsbyImage image={getImage(gatsbyImageData)} alt={ alt } /> }

        //return <img src={file.url} alt={alt} />
    }

    // Non SVG images
    if (classType == IMAGE_TYPE.HERO) {
        return  <GatsbyImage image={getImage(gatsbyImageData)} alt={ alt } /> }
    else {
        return <GatsbyImage image={getImage(gatsbyImageData)} alt={ alt } /> }

    //return <GatsbyImage image={gatsbyImageData} alt={alt}/>
}

export default function RenderContentfulImage(props)
    {
        return (renderImage(props.node, props.classType));
    }